export default class Form {
  
  constructor() {
    const heroForm = document.querySelector('.main-hero .hero-form form'),
    ctaForm = document.querySelector('.cta-form form'),
    forms = document.querySelectorAll('form');

    if (heroForm) {
      this.heroForm = heroForm;
      this.addClassToHeroForm();
    }
    if (ctaForm) {
      this.ctaForm = ctaForm;
      this.addClassToCTAForm();
    }
    if (forms.length) {
      forms.forEach(form => {
        this.form = form;
        this.removeRole();
      });
    }
  }

  addClassToHeroForm() {
    this.heroForm.classList.add('row');
  }

  addClassToCTAForm() {
    this.ctaForm.classList.add('row', 'row-cols-1', 'row-cols-lg-2');
  }
  removeRole() {
    this.form.removeAttribute('role');
  }  
}